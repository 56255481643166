import { useApplicationContext } from '@/context/ApplicationContext';
import type { WorkOrderStatus } from '@/graphql/types';
import theme from '@/plugin/chakra';
import { useCallback } from 'react';
import { IconType } from 'react-icons';
import {
  TbPlayerPauseFilled,
  TbPlayerPlayFilled,
  TbPlayerRecord,
  TbPlayerStopFilled,
} from 'react-icons/tb';
import useTranslation from '../../../utils/i18n/useTranslation';

type ReturnType = {
  getStatusLabel: (status: WorkOrderStatus) => string;
  getStatusAppearanceInfo(value: WorkOrderStatus): WorkOrderStatusAppearanceInfo;
};

type statusDefaultLabelKeyType = 'open' | 'on-hold' | 'in-progress' | 'done';

export type WorkOrderStatusAppearanceInfo = {
  label: string;
  color: string;
  chakraColor: string;
  icon: IconType;
  value: WorkOrderStatus;
  defaultLabelKey: statusDefaultLabelKeyType;
};

const openStatus: WorkOrderStatusAppearanceInfo = {
  value: 'open',
  label: '未着手',
  color: 'blue.600',
  chakraColor: theme.colors.blue[600],
  defaultLabelKey: 'open',
  icon: TbPlayerRecord,
};

const onHoldStatus: WorkOrderStatusAppearanceInfo = {
  value: 'onHold',
  label: '保留中',
  color: 'warning.500',
  chakraColor: theme.colors.warning[500],
  defaultLabelKey: 'on-hold',
  icon: TbPlayerPauseFilled,
};

const inProgressStatus: WorkOrderStatusAppearanceInfo = {
  value: 'inProgress',
  label: '作業中',
  color: 'success',
  chakraColor: theme.colors.success,
  defaultLabelKey: 'in-progress',
  icon: TbPlayerPlayFilled,
};

const doneStatus: WorkOrderStatusAppearanceInfo = {
  value: 'done',
  label: '完了',
  color: 'neutral.500',
  chakraColor: theme.colors.neutral[500],
  defaultLabelKey: 'done',
  icon: TbPlayerStopFilled,
};

const statusLabelMap = new Map<WorkOrderStatus, WorkOrderStatusAppearanceInfo>([
  ['open', openStatus],
  ['onHold', onHoldStatus],
  ['inProgress', inProgressStatus],
  ['done', doneStatus],
]);

export const useWorkOrderStatus = (): ReturnType => {
  const { companySetting } = useApplicationContext();

  const { t } = useTranslation();

  const getStatus = useCallback((value: WorkOrderStatus) => {
    const status = statusLabelMap.get(value);

    if (!status) throw new Error(`Invalid status value: ${value}`);

    return status;
  }, []);

  const getStatusLabel = useCallback(
    (value: WorkOrderStatus) => {
      if (companySetting) {
        // companySettingの中で、mapで持つようにしても良いかもしれない
        const { customWorkOrderStatuses } = companySetting;
        const customStatus = customWorkOrderStatuses.find((entry) => entry.status === value);
        if (customStatus) return customStatus.label;
      }

      const { defaultLabelKey, label } = getStatus(value);
      return t(`status.${defaultLabelKey}`, label);
    },
    [companySetting, getStatus, t]
  );

  const getStatusAppearanceInfo = useCallback(
    (value: WorkOrderStatus) => {
      const status = getStatus(value);
      const label = getStatusLabel(value);

      return { ...status, label };
    },
    [getStatus, getStatusLabel]
  );

  return {
    getStatusLabel,
    getStatusAppearanceInfo,
  };
};
